html{
  margin: 0px;
  padding: 0px;
  width: 100%;
  height:100%; 
}
body{ 
  background: radial-gradient(#12293d,black);
  overflow: hidden;
  color:white;
  font-family: futura, Arial, Helvetica, sans-serif;
}

.overlay{
    background-image: linear-gradient(transparent 0%, rgba(10, 16, 10, 0.5) 50%);
    background-size: 1000px 2px;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.forerunner{
  width: 50rem;
  height: 50rem;
  text-align: center;
  margin-left:auto;
  margin-right:auto;
  font-family: 'ForerunnerPromethean', futura;
  font-size: 4rem;
}


.row{
  display: inline-block;
  
}
p{
  all:unset;
  letter-spacing: 1.9ch;
}

.deletion{
    letter-spacing: 0ch;
}

span:hover{
  color: #fff;
  cursor: pointer;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
}
@keyframes glow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #182f42, 0 0 40px #182f42, 0 0 50px #182f42, 0 0 60px #182f42, 0 0 70px #182f42;
  }
  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #426C8F 0 0 40px #426C8F 0 0 50px #426C8F 0 0 60px #426C8F 0 0 70px #426C8F 0 0 80px #ff4da6;
  }
}
@-webkit-keyframes glow {
  from {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #182f42, 0 0 40px #182f42, 0 0 50px #182f42, 0 0 60px #182f42, 0 0 70px #182f42;
  }
  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #426C8F 0 0 40px #426C8F 0 0 50px #426C8F 0 0 60px #426C8F 0 0 70px #426C8F 0 0 80px #ff4da6;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}



.regular1{
  display: block;
  font-family: futura;
  font-size: 1rem;
  letter-spacing: 1.9ch;
  margin-top: -2rem;
}
h1{
  padding: 0px;
  margin: 0px;
}
.normal{
  font-family: futura,Arial, Helvetica, sans-serif;
  margin-top: -3rem;
  font-size: 4rem;
}

footer{
  position: absolute;
  bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}


@media only screen

  and (min-device-width: 275px)

  and (max-device-width: 812px)

  and (orientation: portrait) {
   
    img{
      display: none;
    }
   .overlay{
     width: 100vw;
   }
   .forerunner{
     width: 100vw;
     height: 30rem;
     font-size: 3rem;
   }

   .regular1{
     font-size: 0.8rem;
   }

   h1{
     font-size: 3rem;
   }

   .translation{
     margin-top: 3rem;
   }

   .kofi{
     position: absolute;
     bottom: 4rem;
   }

}